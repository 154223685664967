import React, { useState } from "react";
import useLatestData from "../utils/useLatestData";
import { HomePageGrid } from "../styles/Grids";
import LoadingGrid from "../components/LoadingGrid";
import ItemGrid from "../components/ItemGrid";
import { graphql, useStaticQuery, Link, navigate } from "gatsby";
import { FiChevronLeft, FiChevronDown } from "react-icons/fi";
import { ImSearch } from "react-icons/im";
import styled from "styled-components";
import { useEffect } from "react";
import fuzzysort from "fuzzysort";

const StyledFiltersPanel = styled.div`
  --input-padding: 7px;

  .textbox-container {
    border: 1px solid var(--light-gray);
    border-radius: 3px;
    background: white;
    display: flex;
    align-items: center;
    padding: calc(var(--input-padding) * 2) var(--input-padding);
    margin-top: 5px;
    font-size: 0.75em;
    max-width: 200px;

    & input {
      border: none;
      outline: none;
      width: 100%;
    }

    & .icon {
      padding-left: var(--input-padding);
      color: var(--light-blue);
      cursor: pointer;
    }
  }

  .title {
  }
  .subtitle {
    font-weight: bold;
    margin-top: 25px;
  }

  .topics-container,
  .content-types-container {
    display: flex;
    flex-direction: column;
  }

  .topic-link,
  .content-type-link {
    color: var(--light-blue);
    font-size: 0.75em;
    margin-top: 10px;
  }

  @media (max-width: 800px) {
    .title {
      display: none;
    }

  .textbox-container {
    max-width: inherit;
  }
`;

const mockTopics = [
  {
    label: "Advertising",
    href: "",
  },
  {
    label: "Analytics",
    href: "",
  },
  {
    label: "Ecommerce",
    href: "",
  },
  {
    label: "Education",
    href: "",
  },
  {
    label: "Startups",
    href: "",
  },
  {
    label: "Leadership",
    href: "",
  },
];

const mockContentTypes = [
  {
    label: "Ebook",
    href: "",
  },
  {
    label: "Webinar",
    href: "",
  },
  {
    label: "Template",
    href: "",
  },
  {
    label: "Tool",
    href: "",
  },
  {
    label: "Kit",
    href: "",
  },
  {
    label: "Course",
    href: "",
  },
  {
    label: "Guide",
    href: "",
  },
];

const FiltersPanel = ({ searchTerm, updateSearchTerm }) => {
  const [topics] = useState(mockTopics);
  const [contentTypes] = useState(mockContentTypes);

  return (
    <StyledFiltersPanel>
      <h3 className="title">Filter Resources</h3>

      <h5 className="subtitle">Search</h5>

      <div className="textbox-container">
        <input
          type="text"
          id="search"
          placeholder="Start typing to filter..."
          value={searchTerm}
          onChange={updateSearchTerm}
        />

        <ImSearch className="icon" />
      </div>

      <h5 className="subtitle">Topics</h5>
      <div className="topics-container">
        {topics.map(({ label }) => (
          <Link key={label} className="topic-link">
            {label}
          </Link>
        ))}
      </div>

      <h5 className="subtitle">Content Type</h5>
      <div className="content-types-container">
        {contentTypes.map(({ label }) => (
          <Link key={label} className="content-type-link">
            {label}
          </Link>
        ))}
      </div>
    </StyledFiltersPanel>
  );
};

const Webinars = ({ webinars, handleWebinarCardClick }) => {
  return (
    <div>
      {!webinars && <LoadingGrid count={4} />}{" "}
      {webinars && !webinars?.length && <p>No webinars available...</p>}
      {!!webinars?.length && (
        <ItemGrid items={webinars} handleClick={handleWebinarCardClick} />
      )}
    </div>
  );
};

const StyledPageContainer = styled.div`
  .filter-panel-container {
    transition: all 0.5s;
    overflow: hidden;
  }

  .main-content {
    display: grid;
    gap: 2rem;
    --columns: 2;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 100px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      --columns: 1;
    }
  }

  .control-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .home-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: var(--light-blue);

    svg {
      margin-right: 6px;
    }
  }

  .filter-dropdown {
    display: none;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    svg {
      margin-left: 6px;
      color: var(--light-blue);
    }
  }

  .page-controls {
    font-size: 0.75em;
  }
  .show-count {
    margin-left: 2rem;
  }
  .show-all {
    color: var(--light-blue);
    cursor: pointer;
    margin-left: 2rem;
  }

  @media (max-width: 800px) {
    .home-link {
      display: none;
    }
    .filter-dropdown {
      display: flex;
    }
  }

  @media (max-width: 800px) {
    .filter-panel-container {
      max-height: 0;
    }
    .filter-panel-container[data-open="true"] {
      box-shadow: 0 0 11px #cbd6e2;
      border-radius: 3px;
      padding: 0 3rem 3rem;
      background: white;
      max-height: 1000px;
    }
  }
`;

export default function ResourcesPage({ data }) {
  // state
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    setWebinars(getFullListOfWebinars);
  }, [data.webinars?.edges?.length]);

  // vars

  // funcs
  const toggleDropdown = () => setFilterDropdownOpen(!filterDropdownOpen);

  const getFullListOfWebinars = () => {
    return data.webinars.edges.map(({ node }) => node);
  };

  const updateSearchTerm = (evt) => {
    const newSearchTerm = evt.target.value.toLowerCase();

    setSearchTerm(newSearchTerm);

    if (!newSearchTerm) {
      setWebinars(getFullListOfWebinars());
      return;
    }

    const filtered = fuzzysort
      .go(newSearchTerm, getFullListOfWebinars(), {
        // keys: ["name", "shortDescription"],
        key: "name",
      })
      .map((result) => result.obj);

    // console.log({
    //   filtered,
    //   // newSearchTerm,
    //   // list: getFullListOfWebinars(),
    //   // opts: {
    //   //   keys: ["name", "shortDescription"],
    //   // },
    // });

    // const filtered = getFullListOfWebinars().filter((webinar) =>
    //   !!newSearchTerm
    //     ? webinar.shortDescription
    //         .concat(webinar.name)
    //         .toLowerCase()
    //         .includes(newSearchTerm)
    //     : true
    // );

    setWebinars(filtered);
  };

  const handleWebinarCardClick = (item) => {
    console.log({
      item,
    });

    navigate(`/webinars/${item.slug.current}`);
  };

  return (
    <StyledPageContainer className="page-wrapper">
      <div className="text-center" style={{ margin: "30px auto 90px auto" }}>
        <h1 style={{ fontWeight: "bold" }}>
          Free Financial Strategy Resources!
        </h1>
        <p>
          All of my webinar content, in one place. Search past recordings as
          well as upcoming events.
        </p>
      </div>

      <div className="control-panel">
        <Link to="/" className="home-link">
          <FiChevronLeft />
          Home
        </Link>
        <div className="filter-dropdown" onClick={toggleDropdown}>
          Filter
          <FiChevronDown />
        </div>

        <div className="page-controls">
          <select>
            <option label="Recent" value="recent" />
            <option label="Title" value="Title" />
          </select>

          <span className="show-count">
            Showing 1 - {webinars.length} of {webinars.length}
          </span>
          {/* <span className="show-all">
            <b>Show All</b>
          </span> */}
        </div>
      </div>

      <div className="main-content">
        <div className="filter-panel-container" data-open={filterDropdownOpen}>
          <FiltersPanel
            //
            searchTerm={searchTerm}
            updateSearchTerm={updateSearchTerm}
          />
        </div>
        <Webinars
          webinars={webinars}
          handleWebinarCardClick={handleWebinarCardClick}
        />
      </div>
    </StyledPageContainer>
  );
}

export const query = graphql`
  query {
    webinars: allSanityWebinar {
      edges {
        node {
          _id
          _type
          shortDescription
          slug {
            _key
            _type
            current
          }
          price
          longDescription
          parent {
            id
          }
          name

          image {
            asset {
              url
              fluid(maxWidth: 1000, maxHeight: 750) {
                ...GatsbySanityImageFluid
              }
              metadata {
                lqip
              }
            }
          }
        }
      }
    }
  }
`;
